<template lang="html">
  <div class="my-4 px-10 py-1">
    <ol class="flex space-x-4 mb-3">
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>&gt;</li>
      <li class="font-bold">
        <router-link to="/facility-management">
          Facility Management
        </router-link>
      </li>
    </ol>

    <div class="mb-3 flex justify-end items-center space-x-3">
      <button type="button" class="btn btn--red-primary">
        Connect Third Party Account
      </button>

      <button type="button" class="btn btn--red-primary">
        Create New Facility
      </button>
    </div>

    <div class="col-span-12">
      <div class="box py-5"></div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="css" module="styles"></style>
